<template>
    <div>
        <div class="container mb-5 mt-3"> 
            <h4 class="text-center mb-3 ">Contact Page</h4>
            <br>
            <div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <iframe class="col-12 col-md-6" style="border:0; width: 100%; height: 100%;" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.3114500422394!2d3.339386473213691!3d6.608167322177318!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b93373c91a795%3A0x4b7774a497af50d!2s29%20Adeniyi%20Jones%2C%20Street%2C%20Ikeja%20100282%2C%20Lagos!5e0!3m2!1sen!2sng!4v1707304144570!5m2!1sen!2sng" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <div class="col-md-6 col-12 text-center">
                        <br class="d-block d-sm-none">
                        <h5 class=" ">Contact details</h5>
                        
                        <p class="heading-contact-page ">Email</p>
                        <p>Enquiries@healthlineng.com</p>
                        <p class="heading-contact-page ">Address</p>
                        <p>29 Adeniyi Jones Avenue,Ikeja-Lagos,Nigeria</p>
                        <p class="heading-contact-page ">Phone</p>
                        <p>+234 7081123545</p>
                        <p>+234 8129937001</p>
                    </div>
                </div>
            </div>
        </div>
        <br>
    </div>
</template>
  
  <script>
  export default {
       name: 'contactView'
  }
  </script>
  
  <style>
  .heading-contact-page{
    font-family: face;
  }

  </style>