<template>
    <div>
       <specProduct></specProduct>
    </div>
</template>

<script>
import specProduct from '@/components/specProduct.vue'

export default {
  name:'specView',
  components:{
    specProduct,
  },
}
</script>

<style>

</style>