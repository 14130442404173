<template>
  <div>
    <addCart></addCart>
  </div>
</template>

<script>
  import addCart from '../components/addCart.vue'
  export default {
    name:'addView',
    components:{
      addCart
    }
  }
</script>

<style>

</style>